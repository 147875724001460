<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Order Date</th>
                    <th>Order no</th>
                    <th>Consultant</th>
                    <th>Admission No</th>
                    <th>Unit</th>
                    <th>Medicine Order</th>
                    <th>Bill</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in tableItems" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.orderNo }}</td>
                    <td> {{ item.consultantName }}</td>
                    <td> {{ item.admissionNo }}</td>
                    <td> {{ item.unit }}</td>
                    <td> <a href="">Print Order</a></td>
                    <td> <a href="">Print Bill</a></td>
                </tr>

                </tbody>
            </table>
            <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ListTable',

        props: {
            tableItems: Array
        }
    }
</script>